import { useState, useEffect } from 'react';

const useTimer = (initialTimeInSeconds, storageKey) => {
  const storedTimeRemaining = sessionStorage.getItem(storageKey);
  const initialTime = storedTimeRemaining
    ? parseInt(storedTimeRemaining, 10)
    : initialTimeInSeconds;

  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timeRemaining]);
  const restartTimer = (time?: number) => {
    setTimeRemaining(time ?? initialTimeInSeconds);
  };

  useEffect(() => {
    sessionStorage.setItem(storageKey, timeRemaining.toString());
  }, [timeRemaining, storageKey]);

  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  const isExpired = timeRemaining === 0;

  return {
    minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
    isExpired,
    restartTimer,
  };
};

export default useTimer;
