import React from 'react';

interface AuthCanvasProps {
  head: string;
  subText?: string;
}

const AuthCanvas: React.SFC<AuthCanvasProps> = (props) => {
  return (
    <div className="bg-white border rounded-lg shadow max-w-[520px] mx-auto p-4 lg:py-10">
      <div className="max-w-[406px] mx-auto">
        <h5 className="font-bold text-[22px] lg:text-2xl mb-2">{props.head}</h5>
        {props.subText && (
          <p className="text-muted text-sm lg:text-[15px] mb-2">
            {props.subText}
          </p>
        )}
        <div className="py-5 lg:py-7">{props.children}</div>
      </div>
    </div>
  );
};

export default AuthCanvas;
