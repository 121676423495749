/// <reference types="webpack-env" />

import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import { persistReducer, persistStore, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import initialize from './initializeStore';
import { rootReducer } from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { State } from './interfaces';

export const configureStore = (
  setToken: (token?: string) => void,
  whitelistReducers?: string[]
) => {
  const persistConfig: PersistConfig<State> = {
    key: 'root',
    storage,
    whitelist: [
      'authUser',
      'cart',
      'brandAndBranch',
      'order',
      'settings',
      ...(whitelistReducers || []),
    ],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    process.env.NODE_ENV === 'production'
      ? compose(applyMiddleware(thunk, loadingBarMiddleware()))
      : composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware()))
  );

  if (process.env.NODE_ENV === 'development' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer as any);
    });
  }

  const persistor = persistStore(store, null, () => {
    initialize(store, setToken);
  });
  return { store, persistor };
};
