import React, { forwardRef, useImperativeHandle } from 'react';
import useTimer from '../../core/hooks/useTimer';

const Timer = (
  {
    timerKey,
    beforeText,
    time,
    children,
  }: {
    beforeText?: string;
    time: number;
    timerKey: string;
    children: any;
  },
  ref
) => {
  const { minutes, seconds, isExpired, restartTimer } = useTimer(
    time,
    timerKey
  );
  useImperativeHandle(ref, () => ({
    restartTimer,
  }));
  return (
    <>
      {isExpired ? (
        <div>{children}</div>
      ) : (
        <>
          {beforeText} {minutes}:{seconds} minutes
        </>
      )}
    </>
  );
};

export default forwardRef(Timer);
