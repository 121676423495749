import api from '../../../api';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// common
export const COMMON_RESET_DATA_ACTION = 'COMMON/RESET_DATA';

export type ResetActionType = IAction<typeof COMMON_RESET_DATA_ACTION>;
