import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProfileBox from '../../components/home/profile-box/ProfileBox';

interface CustomProps {
  className?: string;
  children: any;
  notLoggedIn?: boolean;
}

const Layout = (props: CustomProps) => {
  return (
    <>
      <div
        className={`outer min-h-screen flex flex-col ${props.className ? props.className : ''
          }`}
      >
        <Header notLoggedIn={props.notLoggedIn} />
        {props.children}
        <Footer notLoggedIn={props.notLoggedIn} />
      </div>
    </>
  );
};

export default Layout;
