import React from 'react';

interface CustomProps {
  label: string;
  type: string;
  labelClassName?: string;
  inputClassName?: string;
  placeholder: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  required?: boolean;
  disabled?: boolean;
}

const Input = (
  {
    label,
    type,
    labelClassName,
    inputClassName,
    placeholder,
    onChange,
    onBlur,
    value,
    required,
    disabled,
  }: CustomProps,
  ref
) => {
  return (
    <>
      <label className={labelClassName ?? 'block text-sm mb-1.5'}>
        {label}
        {required && <sup className="text-red-500 text-sm">*</sup>}
      </label>
      <input
        ref={ref}
        disabled={disabled}
        value={value ?? ''}
        onBlur={onBlur}
        onChange={onChange}
        type={type}
        className={inputClassName ?? 'form-control'}
        placeholder={placeholder}
      />
    </>
  );
};

export default React.memo(React.forwardRef(Input));
