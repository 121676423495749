import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process?.env?.REACT_APP_FIREBASE_API_KEY,
  authDomain: process?.env?.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process?.env?.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process?.env?.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process?.env?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process?.env?.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestForToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: process?.env?.REACT_APP_FIREBASE_VAPIDKEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        setTokenFound(null);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
