import { ApiServiceDataStore } from '../data';

export default class ApiService {
  public store: ApiServiceDataStore;

  constructor(store: ApiServiceDataStore) {
    this.store = store;
  }

  get userToken() {
    return this.store.userToken;
  }

  get apiDomain() {
    return this.store.apiDomain;
  }
}
