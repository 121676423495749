import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import {
  authUserReducer,
  settingsReducer,
} from './reducers';
import { State } from './interfaces';

export const rootReducer = combineReducers<State>({
  authUser: authUserReducer,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  settings: settingsReducer,
});
