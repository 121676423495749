import React from 'react';

const ErrorMessage = ({ text }: { text: string }) => {
  return (
    <span className="text-sm" style={{ color: 'red' }}>
      {text}
    </span>
  );
};

export default ErrorMessage;
