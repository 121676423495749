import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from '../store/interfaces';
import {
  logoutAction,
  redirectApplyAction,
  redirectSaveAction,
  profileUpdatedAction,
  getRolesSuccessAction,
  loggedInAction,
} from '../store/reducers/authUser';
import {
  ApiResponse,
  ICustomerBulkImportResponse,
  User,
  UserPermissionTypes,
  UserType,
} from '../api/models';
import { RouteKeys } from '../../containers/routes/route-keys';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../utils/text-utils';

export const useAuth = () => {
  const authState = useSelector((state: State) => state.authUser);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loginError, setLoginError] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const history = useHistory();
  const isSalesPerson: boolean = authState.userType === UserType.Salesperson;

  const login = async (
    data: {
      email: string;
    },
    setToken: (token?: string) => void,
    onSuccess?: () => void
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.login({
        username: data.email,
      });
      setUpdating(false);
      if (response.response === 'Success') {
        {
          response?.errorMsg && toastr.success('', response.errorMsg);
        }
        setLoginError('');
        if (response?.result?.passwordExists) {
          history?.push(RouteKeys?.PasswordForm, {
            storeOperatorID: data?.email,
          });
        } else {
          sessionStorage.removeItem('newPassword');
          // toastr.success(response?.data?.errorMsg, '');
          history?.push(RouteKeys?.OtpForNewPassword, {
            token: response?.result?.userToken,
          });
        }
        redirectApply();
        onSuccess && onSuccess();
      } else {
        setLoginError(response?.errorMsg || 'Invalid Store Operator ID!');
      }
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Invalid Store Operator ID!'
      );
    }
  };

  const submitPassword = async (
    data: {
      email: string;
      password: string;
    },
    setToken: (token?: string) => void,
    onSuccess?: () => void
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.login({
        username: data.email,
        password: data.password,
      });
      if (response.response === 'Success') {
        {
          response?.errorMsg && toastr.success('', response.errorMsg);
        }
        setLoginError('');
        redirectApply();
        onSuccess && onSuccess();
        setUpdating(false);
        dispatch(
          loggedInAction({
            token: response.result?.userToken,
            userType: response.result?.userType,
            user: response.result?.user,
            userStatus: response.result?.userStatus,
          })
        );
        history.push(RouteKeys?.Home);
      } else {
        setUpdating(false);
        setLoginError(response?.errorMsg || 'Invalid Password!');
      }
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Invalid Password!'
      );
    }
  };

  const forgotPassword = async (
    data: {
      email: string;
    },
    onSuccess?: (data?: any) => void
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.forgot({
        userLogin: data.email,
      });
      setUpdating(false);
      if (response?.data?.response === 'Success') {
        {
          response?.data?.errorMsg &&
            toastr.success('', response?.data?.errorMsg);
        }
        sessionStorage.removeItem('forgot');
        // history?.push(RouteKeys?.OtpForNewPassword, { email: data?.email });
        onSuccess && onSuccess(response?.data?.result?.userToken);
      } else if (response?.data?.errorMsg) {
        setLoginError(response?.data?.errorMsg);
      }
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Invalid Email!'
      );
    }
  };

  const resendOtp = async ({ token }) => {
    try {
      let response = await api.auth?.resendOTP(token);
      if (response?.response === 'Success') {
        {
          response?.errorMsg &&
            toastr.success('', response?.errorMsg);
        }
        return { error: false };
      }
    } catch (error) {
      return { error: true };
    }
  };
  const resetPassword = async (
    data: {
      email: string;
      otp: string;
      newPassword: string;
    },
    onSuccess
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.reset({
        userLogin: data.email,
        otp: data?.otp,
        newPassword: data?.newPassword,
      });
      if (response?.data?.response === 'Success') {
        sessionStorage.removeItem('forgot');
        onSuccess && onSuccess();
      } else {
        setLoginError(response?.data?.errorMsg || 'Something went wrong');
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Something went wrong'
      );
    }
  };

  const verifyOtp = async (data: { token: string; otp: string }) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.verifyOtp({
        otp: data.otp,
        token: data.token,
      });
      if (response?.data?.response === 'Success') {
        sessionStorage.removeItem('newPassword');
        setUpdating(false);
        history.push(RouteKeys?.SetNewPassword, {
          token: data.token,
          hideOtp: true,
        });
      } else {
        setLoginError(response?.data?.errorMsg || 'Something went wrong');
        setUpdating(false);
      }
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Something went wrong'
      );
    }
  };
  const newPassword = async (data: { token: string; newPassword: string }) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      let response = await api.auth.newPassword({
        newPassword: data.newPassword,
        token: data.token,
      });
      if (response?.data?.response === 'Success') {
        history.push({ search: 'status=success' });
      } else {
        setLoginError(response?.data?.errorMsg || 'Something went wrong');
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      setLoginError(
        (error as any)?.response?.data?.errorMsg || 'Something went wrong'
      );
    }
  };

  const logout = (logoutLink: string) => {
    dispatch(logoutAction());
    history.push(logoutLink);
  };

  const getRoles = async () => {
    const response = await api.auth.getRoles();
    if (response.result) {
      dispatch(getRolesSuccessAction(response.result));
    } else {
      dispatch(getRolesSuccessAction(undefined));
    }
  };

  const hasRole = (role: UserPermissionTypes) => {
    // if (
    //   authState.userType === UserType.Customer ||
    //   authState.userType === UserType.User
    // ) {
    //   return true;
    // }
    return authState?.roles?.[role] ?? false;
  };

  const redirectSave = (to: string) => {
    dispatch(redirectSaveAction(to));
  };

  const setProfile = async (data: User, onSuccess?: () => void) => {
    dispatch(profileUpdatedAction(data));
  };

  const redirectApply = () => dispatch(redirectApplyAction());

  const customerBulkUploadPreview = async (
    file: File
  ): Promise<ApiResponse<{ data: ICustomerBulkImportResponse[] } | null>> => {
    try {
      setLoader(true);
      const response = await api.auth.bulkUploadPreviewCustomers(file);
      return response;
    } catch (e) {
      toastr.error(translate('common.error'), 'File upload failed');

      return null;
    } finally {
      setLoader(false);
    }
  };

  const submitBulkCustomer = async (ids?: string[]) => {
    try {
      setLoader(true);
      const response: any = await api.auth.submitBulkCustomer(ids?.join(','));
      if (response) {
        return response;
      }
    } catch (e) {
      toastr.error(
        translate('common.error'),
        translate('toast.somethingWentWrong')
      );
      return null;
    } finally {
      setLoader(false);
    }
  };
  return {
    ...authState,
    isAuthenticated: !!authState.userType,
    profile: authState.profile,
    loginError,
    updating,
    loader,
    login,
    newPassword,
    logout,
    resendOtp,
    redirectSave,
    redirectApply,
    setProfile,
    resetPassword,
    getRoles,
    submitPassword,
    hasRole,
    customerBulkUploadPreview,
    submitBulkCustomer,
    verifyOtp,
    isSalesPerson,
    forgotPassword,
  };
};
