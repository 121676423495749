/* eslint-disable @typescript-eslint/no-useless-constructor */

import { CoreApiClient } from '../core/api';

export default class ApiClient extends CoreApiClient {
  // Can add additional services here
  constructor(apiDomain: string) {
    super(apiDomain);
  }
}
