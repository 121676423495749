import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import brand1 from '../../assets/images/brands/img01.svg';
import brand2 from '../../assets/images/brands/img02.svg';
import brand3 from '../../assets/images/brands/img03.svg';
import brand4 from '../../assets/images/brands/img04.svg';

interface CustomProps {
  notLoggedIn?: boolean;
}

const Footer = (props: CustomProps) => {
  const { t } = useTranslation();
  return (
    <>
      {!props.notLoggedIn && (
        <div className="bg-white lg:sticky bottom-0 left-0 px-4 xl:px-6 py-2 lg:py-0 shadow-sm mt-auto">
          <div className="lg:flex items-center justify-between -mx-4">
            <div className="px-4">
              <div className="lg:flex -mx-2.5">
                <div className="px-2.5 self-center text-center lg:text-xs font-medium text-muted lg:order-last">
                  {t('common.OurBrands')}
                </div>
                <div className="px-2.5 py-6 lg:py-0">
                  <div className="flex items-center justify-center -mx-3 sm:-mx-4 overflow-auto">
                    <div className="px-3 sm:px-4">
                      <img alt="brand" src={brand1} className="lg:px-1" />
                    </div>
                    <div className="px-3 sm:px-4">
                      <img alt="brand" src={brand2} className="lg:px-1" />
                    </div>
                    <div className="px-3 sm:px-4">
                      <img alt="brand" src={brand3} className="lg:px-1" />
                    </div>
                    <div className="px-3 sm:px-4">
                      <img alt="brand" src={brand4} className="lg:px-1" />
                    </div>
                  </div>
                </div>
                <div className="px-2.5 py-2 my-1 hidden lg:block">
                  <div className="py-px h-full">
                    <div className="border-l border-[#606060] border-opacity-50 h-full"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 lg:order-first py-2 lg:-0">
              <ul className="lg:flex items-center text-[13px] lg:text-xs font-medium text-muted -mx-4">
                <li className="px-4 py-2">{t('common.CopyRight')}</li>
                <li className="px-4 py-2">
                  <Link to={'/terms-of-use'}>{t('common.TermsofUse')}</Link>
                </li>
                <li className="px-4 py-2">
                  <Link to={'/privacy-policy'}>
                    {t('common.PrivacyPolicy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
