import api from '../../../api';
import { createActionWithPayload, IActionWithPayload } from '../utils';

const SETTINGS_CHANGE_LANGUAGE = 'SETTINGS/CHANGE_LANGUAGE';

export interface ILocale {
  languageID: number;
  languageName: string;
  languageKey: string;
  isRtl: boolean;
}

export class EnglishLocale implements ILocale {
  languageID: number = 1;
  languageName: string = 'English';
  languageKey: string = 'en';
  isRtl: boolean = false;
}
export class ArabicLocale implements ILocale {
  languageID: number = 3;
  languageName: string = 'عربي';
  languageKey: string = 'ar';
  isRtl: boolean = true;
}

export interface SettingsState {
  locale: ILocale;
}

type ChangeLanguageActionType = IActionWithPayload<
  typeof SETTINGS_CHANGE_LANGUAGE,
  number
>;

type Actions = ChangeLanguageActionType;

const initialState: SettingsState = {
  locale: new EnglishLocale(),
};

export const changeLanguageAction = (languageID: number) => {
  return createActionWithPayload(SETTINGS_CHANGE_LANGUAGE, languageID);
};

// reducer
export const settingsReducer = (
  state: SettingsState = initialState,
  action: Actions
): SettingsState => {
  switch (action.type) {
    case SETTINGS_CHANGE_LANGUAGE:
      let locale: ILocale;
      switch (action.payload) {
        case 1:
          locale = new EnglishLocale();
          break;
        case 3:
          locale = new ArabicLocale();
          break;
        default:
          locale = new EnglishLocale();
      }
      api.setLanguageID(action.payload);
      return {
        ...state,
        locale,
      };
    default:
      return state;
  }
};
