import React from 'react';
import loaderIcon from './../loader.svg';
import { LoaderProps } from '..';

interface ContainerLoaderProps extends LoaderProps {
  height?: number;
  maxHeight?: number;
}
const ContainerLoader: React.SFC<ContainerLoaderProps> = (
  props: ContainerLoaderProps
) => {
  return (
    <div
      className="loader flex flex-col items-center justify-center"
      style={
        props.maxHeight
          ? { maxHeight: props.maxHeight }
          : { minHeight: props.height || 100 }
      }
    >
      <div className="relative w-16 h-16">
        <img
          src={loaderIcon}
          alt=".."
          className="absolute inset-0 m-auto z-40"
        ></img>
        <div className="circle relative w-16 h-16 rounded-full before:absolute before:inset-0 before:m-auto before:w-[54px] before:h-[54px] before:bg-white before:rounded-full"></div>
      </div>
      {props.text && (
        <div className="text-sm font-medium pt-2">{props.text}</div>
      )}
    </div>
  );
};

export default ContainerLoader;
