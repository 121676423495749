import React, { useEffect, useState } from 'react';
import Layout from '../../core/layouts/Layout';
import AuthCanvas from '../../components/auth/AuthCanvas';
import { useTranslation } from 'react-i18next';
import OTP from '../../components/otp';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../core/hooks';
import { FullPageLoader } from '../../components/loader';
import Timer from '../../components/timer/Timer';
import { toastr } from 'react-redux-toastr';

const OtpConfirmation: React.SFC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const location: any = useLocation();

  const otpSubmit = (data: any) => {
    console.log('🚀 ~ otpSubmit ~ data:', data);
    const otp = `${data?.input1}${data.input2}${data?.input3}${data?.input4}`;
    auth.verifyOtp({ otp, token: location?.state?.token });
  };
  const childRef: any = React.createRef();

  useEffect(() => {
    if (auth.loginError) {
      toastr.error('Error', auth.loginError);
    }
  }, [auth.loginError]);

  const resendOtp = async () => {
    const res = await auth?.resendOtp({ token: location?.state?.token });
    if (res.error) {
      toastr.error(t('common.somethingwentwrong'), '');
    } else {
      childRef?.current?.restartTimer();
    }
  };
  return (
    <Layout className="bg-slate-50" notLoggedIn>
      {auth?.updating && <FullPageLoader text="Loading..." />}
      <div className="container pt-1.5 lg:pt-10 pb-14">
        <AuthCanvas
          head={t('common.VerifyOTP')}
          subText={t('common.VerifyOTPSubtext')}
        >
          <OTP valueOnSubmit={otpSubmit} />
          <div className="text-center text-sm font-medium">
            <Timer
              timerKey={'newPassword'}
              beforeText={t('common.ResendOTPIn')}
              time={300}
              ref={childRef}
            >
              <span
                onClick={resendOtp}
                className="text-primary text-underline cursor-pointer"
              >
                {t('common.ResendOTP')}
              </span>
            </Timer>
          </div>
        </AuthCanvas>
      </div>
    </Layout>
  );
};

export default OtpConfirmation;
