import i18n from 'i18next';
import translationEN from './locales/en.json';
import translationAR from './locales/ar.json';
import { useTranslation, initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  // keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    // wait: true,
    // bindStore: false,
  },
  debug: true,
});

export default i18n;
